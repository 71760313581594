import React from 'react';
import styled from 'styled-components';
import { Whatsapp } from 'styled-icons/boxicons-logos/Whatsapp';

const Button = styled.a`
  position: fixed;
  width: 60px;
  height: 60px;
  bottom: 30px;
  right: 30px;
  background-color: #25d366;
  color: #fff;
  border-radius: 50px;
  text-align: center;
  box-shadow: 2px 2px 3px #999;
  z-index: 1200;
`;

const WhatsappIcon = styled(Whatsapp)`
  margin-top: 14px;
`;

const WhatsappButton = () => (
  <Button
    href="https://api.whatsapp.com/send?phone=5519997845085&text=*Ol%C3%A1%20boa%20noite!*%F0%9F%8D%95%20Vi%20o%20card%C3%A1pio%20no%20site%20e%20gostaria%20de%20fazer%20um%20pedido%0A%0A"
    aria-label="Click aqui para fazer pedido via whatsapp"
  >
    <WhatsappIcon size={30} />
  </Button>
);

export default WhatsappButton;
