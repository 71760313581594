import React from 'react';
import PropTypes from 'prop-types';
import { StaticQuery, graphql } from 'gatsby';
import styled, { createGlobalStyle } from 'styled-components';

import Header from './header';
import WhatsappButton from './WhatsappButton';

const GlobalStyle = createGlobalStyle`
  body {
    background-color: #ccc;
  }

  a {
    color: inherit;
    text-decoration: none;
  }
`;

const Container = styled.div`
  background-color: #ffffff;
  max-width: 800px;
  margin: 0 auto;
  box-shadow: 10px 10px 10px -7px rgba(0, 0, 0, 0.51);

  @media (min-width: 830px) {
    margin: 30px auto;
    border-radius: 6px;
  }
`;

const Footer = styled.footer`
  padding: 0 0 30px 0;
  text-align: center;
  text-transform: uppercase;
  font-size: 0.875rem;
  color: #777;

  a {
    text-decoration: underline;
  }
`;

// const Manutencao = styled.div`
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   height: 100vh;
// `;

const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <>
        <GlobalStyle />

        {/* <Manutencao>
          <h2>Em manutenção.</h2>
        </Manutencao> */}

        <Container>
          <Header siteTitle={data.site.siteMetadata.title} />

          {children}

          <Footer>
            <p>
              © {new Date().getFullYear()} - {data.site.siteMetadata.title}
            </p>

            <p>
              Cardápio desenvolvido por{' '}
              <a href="https://mznapplab.com">Site Cardápio</a>
            </p>
          </Footer>
        </Container>

        <WhatsappButton />
      </>
    )}
  />
);

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
