import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Map } from 'styled-icons/boxicons-regular/Map';
import { Whatsapp } from 'styled-icons/boxicons-logos/Whatsapp';
import { Facebook } from 'styled-icons/boxicons-logos/Facebook';
import { Instagram } from 'styled-icons/boxicons-logos/Instagram';
import { Clock } from 'styled-icons/fa-regular/Clock';
import { PhoneCall } from 'styled-icons/boxicons-regular/PhoneCall';

import bg from '../images/bg.jpg';

const HeaderWrapper = styled.header`
  background-image: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)),
    url(${bg});
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  color: #ffffff;
  /* color: #00a85a; */
  text-align: center;
  /* font-weight: 700; */
  padding-top: 5px;
  padding-bottom: 5px;
  position: relative;

  @media (min-width: 830px) {
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
  }
`;

const Container = styled.div`
  padding-left: 15px;
  padding-right: 15px;
`;

const Social = styled.div`
  padding: 9px 0;
  a {
    padding: 0 7px;
  }
`;

const Phones = styled.p`
  font-size: 1.25rem;
  font-weight: 700;
  margin-bottom: 0;
  span {
    white-space: nowrap;
  }
`;

const ImageWrapper = styled.div`
  .gatsby-image-wrapper {
    max-width: 250px;
    margin: 15px auto;
  }
`;

const Hours = styled.p`
  margin-top: 10px;
`;

const Address = styled.p``;

const IconWhatsapp = styled(Whatsapp)`
  margin: 0 2px 3px 2px;
`;

const IconPhoneCall = styled(PhoneCall)`
  margin: 0 2px 3px 2px;
`;

const IconFacebook = styled(Facebook)`
  background: #3b5998;
  color: #ffffff;
  padding: 3px;
  border-radius: 50%;
  height: 30px;
  width: 30px;
`;

const IconInstagram = styled(Instagram)`
  background: #d6249f;
  background: radial-gradient(
    circle at 30% 107%,
    #fdf497 0%,
    #fdf497 5%,
    #fd5949 45%,
    #d6249f 60%,
    #285aeb 90%
  );
  color: #ffffff;
  padding: 3px;
  border-radius: 50%;
  height: 30px;
  width: 30px;
`;

const IconMap = styled(Map)`
  background: #dd4b39;
  color: #ffffff;
  padding: 3px;
  border-radius: 50%;
  height: 30px;
  width: 30px;
`;

const Header = ({ siteTitle }) => (
  <StaticQuery
    query={graphql`
      query {
        logo: file(relativePath: { eq: "logo.png" }) {
          childImageSharp {
            fluid(maxWidth: 250, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `}
    render={data => (
      <HeaderWrapper>
        <Container>
          <Social>
            <a
              href="https://www.facebook.com/pizzariaitaliapiracicaba"
              aria-label="Visitar página no Facebook"
            >
              <IconFacebook size={23} />
            </a>
            <a
              href="https://www.instagram.com/pizzariaitaliapiracicaba/"
              aria-label="Visitar página no Instagram"
            >
              <IconInstagram size={23} />
            </a>
            <a
              href="https://goo.gl/maps/2Lb3o2APTfL2emdq7"
              aria-label="Visitar local no Google Maps"
            >
              <IconMap size={23} />
            </a>
          </Social>

          <Phones>
            <IconPhoneCall size={28} /> 3402.4060 / 3402.2157 /{' '}
            <span>
              99784.5085 <IconWhatsapp size={30} />
            </span>
          </Phones>

          <ImageWrapper>
            <Img
              fluid={data.logo.childImageSharp.fluid}
              alt={`Logo ${siteTitle}`}
            />
          </ImageWrapper>

          <Hours>
            <Clock size={16} /> Aberto todos os dias (exceto Terças) das 18 às
            0h <br /> Abrimos todas vésperas de feriados
          </Hours>

          <Address>
            <Map size={23} /> Av. Dona Jane Conceição, 1455 - B. Paulista <br />
            Piracicaba | SP
          </Address>
        </Container>
      </HeaderWrapper>
    )}
  />
);

Header.propTypes = {
  siteTitle: PropTypes.string,
};

Header.defaultProps = {
  siteTitle: ``,
};

export default Header;
